<template>
    <div style="width: auto; height: 100%; display: flex; justify-content: space-between; align-items: center;">
        <div class="ele-container" v-if="hasExpand">
            <template>
                <div class="village-box">
                    <el-table :data="villageList" style="width: 100%;height: 100%;" size="small" border height="100%" stripe @row-click="selectVillage" :cell-style="tableCellStyle">
                        <el-table-column prop="v_village_name" label="小区列表" align="center">
                        </el-table-column>
                    </el-table>
                </div>
                <div class="elevator-box">
                    <el-table :data="elevatorList" style="width: 100%;height: 100%;" size="small" border height="100%" stripe @row-click="selectElevator" :cell-style="tableCellStyle">
                        <el-table-column prop="v_elevator_name" label="电梯列表" align="center">
                        </el-table-column>
                    </el-table>
                </div>
            </template>
        </div>
        <div class="box-switch" @click="switchBox">
            <img style="width:20px; height:auto;" :src="hasExpand?require('@/assets/ic_open.jpg'):require('@/assets/ic_close.jpg')">
            <!-- <i class="el-icon-d-arrow-right" /> -->
        </div>
    </div>
</template>
<script>
import { getElevatorList } from '@/api/monitor/index'
import {
    searchVillageSelectList
} from '@/api/home/index.js'
export default {
    data() {
        return {
            villageList: [],
            elevatorList: [],
            checkedElevatorId: '',
            checkedElevatorCode: '',
            currentVillageId: '',
            switchWidth: 30,
            hasExpand: true,
            apiData: {
                pageSize: 99999,
                pageIndex: 1,
            },
            autoLoadElevator: false,
            routerPass: false
        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        }
    },
    methods: {
        tableRowClassName({ row, rowIndex }) {
            return 'ele-checked-row';
        },
        selectElevator(row, column, event) {
            this.checkedElevatorId = row.v_elevator_id
            this.checkedElevatorCode = row.v_elevator_code
            this.$emit('didSelectElevator', row)
        },
        selectVillage(row, column, event) {
            this.autoLoadElevator = false
            if (this.currentVillageId != row.v_village_id) {
                let params = this.apiData
                params["vVillageId"] = row.v_village_id

                getElevatorList(params).then(res => {
                    if (res.code === '0000') {
                        this.elevatorList = res.info.list
                    } else {
                        console.log("res", res)
                    }
                })

                this.currentVillageId = row.v_village_id
                this.$emit('didSelectVillage', row)
            }
        },
        tableCellStyle({ row, column, rowIndex, columnIndex }) {
            let res = {}
            if (this.theme == 'dark') {
                res["color"] = "#fff!important"
                res["background"] = "#0d1d4d!important"
            }
            if (this.currentVillageId == row.v_village_id) {
                res["background"] = "#007BE5!important"
                res["color"] = "#fff!important"
            }
            if ((this.checkedElevatorId && this.checkedElevatorId == row.v_elevator_id) || (this.checkedElevatorCode && this.checkedElevatorCode == row.v_elevator_code)) {
                res["background"] = "#007BE5!important"
                res["color"] = "#fff!important"
            }
            return res;

        },
        updateExpand() {
            this.$emit('onExpand', this.hasExpand)
        },
        switchBox() {
            this.hasExpand = !this.hasExpand
            this.$emit('onExpand', this.hasExpand)
        },
        setDefaultSelect(villageId, elevatorCode, _routerPass = true) {
            this.currentVillageId = villageId
            this.checkedElevatorCode = elevatorCode
            this.autoLoadElevator = true
            this.routerPass = _routerPass
            // let params = this.apiData
            // params["vVillageId"] = villageId
            // getElevatorList(params).then(res => {
            //     if (res.code === '0000') {
            //         this.elevatorList = res.info.list
            //     } else {
            //         console.log("res", res)
            //     }
            // })
        },
        buildParams(params) {
            params.vProjectId = this.user.user.v_project_id
            return params
        },
    },
    watch: {

    },
    mounted() {
        let params = { ...this.apiData }
        // params["vProjectId"] = '8089373891892543488'
        searchVillageSelectList(this.buildParams(params)).then(res => {
            if (res.code === '0000') {
                this.villageList = res.info

                if (this.villageList.length > 0) {
                    if (!this.routerPass)
                        this.setDefaultSelect(this.villageList[0]['v_village_id'], null, false)
                }
            } else {
                console.log("res", res)
            }
            if (this.autoLoadElevator) {

                this.$emit('didSelectVillage', this.villageList.find((item) => {
                    return item.v_village_id == this.currentVillageId
                }))

                let params = this.apiData
                params["vVillageId"] = this.currentVillageId
                getElevatorList(params).then(res => {
                    if (res.code === '0000') {
                        this.elevatorList = res.info.list

                        if (this.elevatorList.length > 0) {
                            if (!this.routerPass)
                                this.selectElevator(this.elevatorList[0], null, null)
                        }
                    } else {
                        console.log("res", res)
                    }
                })
            }
        })
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.ele-checked-row {
    background: #007BE5 !important;
}

.ele-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ele-icon {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    font-size: 20px;
    font-weight: 100;
}

.box-switch {
    height: 30px;
    margin-right: 0;
}

.el-table ::v-deep th.el-table__cell>.cell {
    @include font_color("tableHeaderTextColor");
}

.el-table {
    ::v-deep .el-table__cell {
        padding: 8px 0;
        @include font_color("tableCellTextColor");
        @include background_color("tableEmptyBgColor");
    }
}

.el-table {
    ::v-deep .el-table__empty-block {
        @include background_color("tableEmptyBgColor");
    }
}

.el-table ::v-deep.el-table--fit {
    @include background_color(tableBgColor);
}

.el-table ::v-deep td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
    @include border_color(panelBorderColor);
}

.el-table ::v-deep th.el-table__cell.is-leaf {
    @include border_color(panelBorderColor);
}

.el-table--border,
::v-deep .el-table--group {
    @include border_color(panelBorderColor);
}

.el-table--border::after,
::v-deep .el-table--group::after,
.el-table::before {
    @include background_color(panelBorderColor);
}

.village-box {
    width: 100px;
    height: 100%;
    min-width: 100px;
}

.elevator-box {
    width: 165px;
    height: 100%;
    min-width: 165px;
}

::v-deep .el-table__body-wrapper {
    overflow-x: hidden;
}

::v-deep .el-table__empty-text {
    font-size:12px;
}
</style>