<template>
    <div style="height:100%;">
        <SideLayout ref="sideRef" :minLeftWidth="expand ? 0 : 240" :minRightWidth="1066" @onExpand="onLayoutExpand">
            <template v-slot:left-content>
                <div class="left-content-wrapper">
                    <ElevatorRadioSelector ref="radioSelRef" @didSelectElevator="onSelectElevator" @didSelectVillage="onSelectVillage" />
                </div>
            </template>
            <template v-slot:right-content>
                <div class="right-content-wrapper">
                    <div class="monitor-left">
                        <div class="ml-top">
                            <div class="header">{{checkedVillage?checkedVillage.v_village_name:'--'}}</div>
                            <div class="video-wrapper">
                                <ElevatorVideo :showMonitor="true" :elevator="checkedElevator" :num="0" />
                            </div>
                        </div>
                        <div class="ml-bottom">
                            <div class="mlb-left">
                                <ElevatorInfo ref="infoRef" />
                            </div>
                            <div class="mlb-right">
                                <ElevatorMaintenance ref="maintenaceRef" />
                                <!--  <div class="mlbr-top">
                                    <ElevatorFault ref="faultRef" @relay="onReplayBy"/>
                                </div>
                                <div class="mlbr-bottom">
                                    <ElevatorRescue ref="sleepyRef" @relay="onReplayBy" />
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="monitor-right">
                        <div style="display:flex; flex-direction: column; gap:10px; flex: 3;">
                            <div style="flex:3;">
                                <ElevatorMonitor ref="monitorRef" />
                            </div>
                            <div style="flex:1;">
                                <ElevatorFault ref="faultRef" @relay="onReplayBy" />
                            </div>
                        </div>
                        <div style="display:flex; flex-direction: column; gap:10px; flex:1">
                            <div style="flex:3;">
                                <ElevatorRescue ref="sleepyRef" @relay="onReplayBy" />
                            </div>
                            <div style="flex:1;">
                                <ElevatorHeatMap ref="heatMapRef" />
                                <!-- <ElevatorMaintenance ref="maintenaceRef" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </SideLayout>
        <MXFaultModal ref="faultModalRef" />
    </div>
</template>
<script>
import SideLayout from '@/components/sideLayout'

import ElevatorRadioSelector from '../components/elevatorRadioSelector'
import ElevatorVideo from '../components/elevatorVideo'
import ElevatorFault from '../components/elevatorFault'
import ElevatorRescue from '../components/elevatorRescue'
import ElevatorInfo from '../components/elevatorInfo'
import ElevatorMonitor from '../components/elevatorMonitor'
import ElevatorMaintenance from '../components/elevatorMaintenance'
import ElevatorHeatMap from '../components/elevatorHeatMap'

import MXFaultModal from '@/pages/components/mx-biz-components/mx-fault-modal'
import { getElevatorInfo, getElevatorHlsInfo } from '@/api/elevator/index'

import {
    queryFaultList,
    getFaultDetail,
    getElevatorFaultRealTimeByEvent
} from '@/api/repair/index.js'
export default {
    data() {
        return {
            expand: false,
            checkedElevator: '',
            checkedVillage: null,
            infoRef: null,
            faultRef: null,
            sleepyRef: null,
            faultImageList: '',

            faultListQuery: {
                iUncivilizedBehaviorFlag: 0,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-180) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                overdue: '',
                pageIndex: 1,
                pageSize: 100,
            },
            sleepyListQuery: {
                iUncivilizedBehaviorFlag: 0,
                villageId: '',
                buildingId: '',
                vElevatorCode: '',
                dtReportTime: this.formatDate(-180) + '',
                dtEndTime: this.formatDate(0) + ' 23:59:59',
                overdue: '',
                pageIndex: 1,
                pageSize: 10,
                iFaultType: '7,8'
            },

        }
    },
    components: {
        SideLayout,
        ElevatorFault,
        ElevatorRescue,
        ElevatorInfo,
        ElevatorMonitor,
        ElevatorMaintenance,
        ElevatorHeatMap,
        ElevatorVideo,
        ElevatorRadioSelector,
        MXFaultModal
    },
    mounted() {
        this.$refs.sideRef.updateExpand()
        let query = this.$route.query
        if (query.villageId && query.elevatorCode && query.registerNum && query.elevatorId) {
            this.$refs.radioSelRef.setDefaultSelect(query.villageId, query.elevatorCode)
            let elevator = {
                v_elevator_id: query.elevatorId,
                v_elevator_code: query.elevatorCode,
                register_number: query.registerNum,
            }

            this.onSelectElevator(elevator)
            if (query.eventId && query.faultId)
                this.replay(query.faultId, query.eventId)
        }

    },

    destroyed() {
        this.timer.remove(this)
    },

    created() {
        // console.log(this.$route,'12312');
    },

    methods: {
        getInfo(elevator) {
            getElevatorInfo({
                register_number: elevator.register_number,
            }).then((res) => {
                this.$refs.infoRef.updateByInfo(res.info)
                this.$refs.maintenaceRef.updateByInfo(res.info)
                this.$refs.heatMapRef.updateByInfo(res.info)

                this.checkElevatorStatus()
            })
        },
        getRecords(elevator) {
            this.faultListQuery.vElevatorCode = elevator.v_elevator_code
            queryFaultList(this.faultListQuery).then((res) => {
                let arr = res.info ? res.info.list : []
                this.$refs.faultRef.updateByInfo(arr.filter((item) => {
                    return item.i_fault_type != 7 && item.i_fault_type != 8
                }))
                this.$refs.sleepyRef.updateByInfo(arr.filter((item) => {
                    return item.i_fault_type == 7 || item.i_fault_type == 8
                }))
            })
            queryFaultList(this.sleepyListQuery).then((res) => {
                this.$refs.sleepyRef.updateByInfo(res.info ? res.info.list : [])
                // this.$refs.sleepyRef.updateByInfo(res.info ? res.info.elevatorHlsInfoSleepy : [])
            })
        },
        getFaultDetail(faultId) {
            getFaultDetail(faultId).then(res => {
                if (res.code === '0000') {
                    this.videoUrl = res.info.videoUrl
                }
            })
        },
        async checkElevatorStatus() {
            if (this.checkedElevator) {
                try {
                    const res = await getElevatorInfo({
                        register_number: this.checkedElevator.register_number,
                    })
                    this.$refs.monitorRef.updateRepairSwitch(res.info.i_mode_status == 1)
                } finally {
                    this.timer.add({
                        ref: this,
                        func: 'checkElevatorStatus',
                        timeout: 3
                    })
                }
            }

        },
        replay(faultId, eventId) {
            this.$refs.faultModalRef.show(faultId, eventId)
        },

        updateFaultModal(realDataShow) {
            if (realDataShow) {
                this.faultModalWidth = 1200
                this.videoWidthRatio = 12
                this.faultInfoWidthRatio = 6
                this.rescueWidthRatio = 6
            } else {
                this.faultModalWidth = 1000
                this.videoWidthRatio = 16
                this.faultInfoWidthRatio = 8
            }
        },


        onReplayBy(data) {
            this.replay(data.faultId, data.eventId)
        },
        onSelectElevator(elevator) {
            this.checkedElevator = elevator
            this.getInfo(elevator)
            this.getRecords(elevator)
            this.$refs.monitorRef.updateByInfo(elevator)
        },
        onSelectVillage(village) {
            this.checkedVillage = village
        },
        onTapExpand() {
            this.$refs.sideRef.updateExpand()
        },
        onLayoutExpand(expand) {
            this.expand = expand
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.left-content-wrapper {
    height: 100%;
    position: relative;
}

.right-content-wrapper {
    position: relative;
    margin: 10px;
    height: calc(100% - 20px);
    display: flex;
    min-height: 800px;
}

.header {
    line-height: 45px;
    height: 45px;
    font-size: 16px;
    @include font_color(tablePageTitleTextColor);
    padding: 0 20px;
    font-weight: 600;
    @include background_color(singleMonitorTableHeaderBgColor);
}

.video-wrapper {
    background-color: #383C4B;
    width: 100%;
    height: calc(100% - 45px);
}

.monitor-left {
    width: calc((100% - 10px)/ 10 * 7);
}

.monitor-right {
    width: calc((100% - 10px)/ 10 * 3);
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
}

.ml-top {
    height: calc((100% - 10px)/ 4 * 3);
    border: 1px solid;
    @include border_color(panelBorderColor);
}

.ml-bottom {
    margin-top: 10px;
    height: calc((100% - 10px)/ 4 * 1);
    display: flex;
    gap: 10px;
}

.mlb-left {
    flex: 100;
}

.mlb-right {
    flex: 46;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.mlbr-top {
    flex: 1;
    background-color: #fff;
}


.mlbr-bottom {
    flex: 1;
    background-color: #fff;
}

.mr-top {
    flex: 2;
}

.mr-bottom {
    flex: 2;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.mrb-top {
    flex: 7;
    display: flex;
    flex-direction: column;
}

.mrb-bottom {
    flex: 1;
}


.live-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 18px;

    h2 {
        margin: 16px;
    }

    &>.item {
        flex: 1;
        position: relative;
        margin-bottom: 15px;

        .box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 50px;
            border: 1px solid rgb(134, 131, 131);
            border-radius: 25px;
            align-items: center;
            justify-content: center;

            &>div {
                width: 100%;
            }
        }

        .title {
            font-size: 18px;
            color: #515A6e;
        }

        .text {
            display: flex;
            width: 100%;
            padding: 0 10px;
            margin-top: 4px;
            font-size: 14px;
            color: #515A6e;
            justify-content: space-around;
        }

        &.active {
            background-image: linear-gradient(to right, #00ffff, #00aff8);

            .title,
            .text {
                color: #0d2f6a;
            }
        }

        &:after {
            position: absolute;
            transform: translateX(-50%);
            width: 1px;
            background-color: #000;
            content: '';
            left: 50%;
            top: 50px;
            height: 15px;
        }

        &:last-child:after {
            background-color: transparent;
        }
    }
}
</style>